import React from 'react';
import {styled} from "@mui/material/styles";
import Box from "@mui/joy/Box";

// @ts-ignore
export const ModalContainer = styled(Box)`
    position: fixed;
    display: flex;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
`

const Background = styled(Box)`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
`

// @ts-ignore
export const Window = ({active, setActive, onClose = () => {}, children}) => {
    function onBackgroundClick() {
        setActive(false);
        onClose();
    }

    return (<>
        {active && <Background onClick={onBackgroundClick}/>}
        <ModalContainer>
            {active && children}
        </ModalContainer>
    </>);
};